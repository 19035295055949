import {
  FREE_SHIPPING_AMOUNT,
  HIGHT_SHIPPING_AMOUNT,
  ONETIME_DELIVERY_AMOUNT_UNDER_LIMIT,
} from './constants';
import Big from 'big.js';
import { getCouponById } from './coupons';
import { getProductById } from './products';

type CartItemType = {
  // TODO
  id: number;
  name: string;
  price: number;
  priceId: number;
  quantity: number;
  // productQuantity: number;
  type: string;
  sku: string;
  shippingGrossPrice: number;
  metadata: object;
  // descriptionHtml: string;
};

type IntervalType = {
  interval: string | null;
  intervalCount: number | null;
};

export const bigToString = (price: Big | number): string =>
  Big(price).div(100).toFixed(2);

export const bigToInt = (price: Big | number): number =>
  parseInt(Big(price).toFixed(0), 10);

export const bigToNumber = (price: Big | number): number =>
  bigToInt(Big(price).div(100)); //.toNumber();

export const bigToFloat = (price: Big | number): number =>
  parseFloat(Big(price).toFixed(2));

export const formatPrice = (price: Big | number): string =>
  `${bigToString(price)} zł`;

export const formatShortPrice = (price: Big | number): string =>
  `${bigToFloat(Big(price).div(100))} zł`;

export const formatVatRate = (vatRate: Big | number): string =>
  `${Big(vatRate).times(100)}%`;

export const grossToNet = (gross: Big | number, vatRate: Big | number): Big =>
  // gross / (1 + vatRate);
  Big(gross).div(Big(vatRate).plus(1));

export const netToGross = (net: Big | number, vatRate: Big | number): Big =>
  // net * (1 + vatRate);
  Big(net).times(Big(vatRate).plus(1));

export const grossToVat = (gross: Big | number, vatRate: Big | number): Big =>
  // (gross / (1 + vatRate)) * vatRate;
  Big(gross).div(Big(vatRate).plus(1)).times(Big(vatRate));

export const netToVat = (net: Big | number, vatRate: Big | number): Big =>
  // net * vatRate
  Big(net).times(Big(vatRate));

export function calculateSubtotalGrossAmount(items: any): Big {
  // accumulator + item.price * item.quantity,
  return Object.values(items).reduce(
    (accumulator: Big, item: any) =>
      Big(item.price).times(item.quantity).plus(accumulator),
    Big(0)
  );
}

export function calculateTotalGrossAmount(
  subtotalGrossAmount: Big,
  discountGrossAmount: Big,
  shippingGrossAmount: Big
): Big {
  return subtotalGrossAmount
    .minus(discountGrossAmount)
    .plus(shippingGrossAmount);
}

export function calculateTotalGrossAmountWithoutShipping(
  subtotalGrossAmount: Big,
  discountGrossAmount: Big
): Big {
  return subtotalGrossAmount.minus(discountGrossAmount);
}

// @ts-ignore
export function calculateTotalVatAmount(items: any, postalCode?: string): Big {
  return Big(0); // TODO @vadym
}

export function calculateDiscountGrossAmount(
  appliedCoupons: any,
  subtotalGrossAmount: Big
): Big {
  if (!appliedCoupons?.length) {
    return Big(0);
  }

  let totalAmountOff = Big(0);
  let totalPercentOff = Big(0);

  for (const coupon of appliedCoupons) {
    if (coupon.amountOff) {
      totalAmountOff = Big(coupon.amountOff).plus(totalAmountOff);
    }
    if (coupon.percentOff) {
      totalPercentOff = Big(coupon.percentOff).plus(totalPercentOff);
    }
  }

  const percentDiscountAmount = Big(subtotalGrossAmount)
    .minus(totalAmountOff)
    .times(totalPercentOff);
  return totalAmountOff.plus(percentDiscountAmount);
}

export function calculateShippingGrossAmount(
  items: {
    [id: string]: CartItemType;
  },
  // @ts-ignore
  postalCode?: string,
  subscriptionInterval?: IntervalType,
  subtotalGrossAmount?: Big,
  discountGrossAmount?: Big
): Big {
  // let price = parseInt(getShippingPrice(), 10);
  // TODO rework @vadym
  let price = Object.values(items).reduce(
    (accumulator: Big, item: any) =>
      Big(item.shippingGrossPrice).plus(accumulator),
    Big(0)
  );

  // TODO implement this
  // if (isShippingZone2(postalCode)) {
  //   price += 1230;
  // }

  // TODO if more than 1 box add 12.30 * quantity-1 boxes

  // If one-time big box over 160zł -> free shipping
  // const box = Object.values(items).find(({ type }) => type === BOX);
  if (
    // box?.sku === BIG_BOX_SHORT &&
    subscriptionInterval &&
    !subscriptionInterval.interval &&
    subtotalGrossAmount &&
    discountGrossAmount
  ) {
    if (bigToInt(subtotalGrossAmount) > FREE_SHIPPING_AMOUNT) {
      return Big(0);
    } else if (bigToInt(subtotalGrossAmount) < HIGHT_SHIPPING_AMOUNT) {
      return Big(ONETIME_DELIVERY_AMOUNT_UNDER_LIMIT);
    }
  }

  return price;
}

export const validateCart = (
  cart: any,
  cartAppliedCoupons: any,
  postalCode: string
) => {
  const SHIPPING_VAT_RATE = 0.23;
  const items = [];

  for (const itemId in cart) {
    const cartItem = cart[itemId];
    const product = getProductById(itemId);

    if (!product) {
      throw new Error(`Product ${itemId} not found!`);
    }
    if (product.price !== cartItem.price) {
      throw new Error(`Product ${itemId} price doesn't match!`);
    }

    const { id, type, price, vatRate } = product;
    const { quantity } = cartItem;

    items.push({
      id,
      type,
      name: product.metadata.invoiceName,
      quantity,
      price,
      vatRate,
    });
  }

  const subtotalGrossAmount = items.reduce(
    (accumulator: Big, item: any) =>
      Big(item.price).times(item.quantity).plus(accumulator),
    Big(0)
  );

  // calc shipping
  const shippingGrossAmount = calculateShippingGrossAmount(cart, postalCode);
  if (shippingGrossAmount.gt(0)) {
    items.push({
      id: 'shipping',
      type: 'shipping',
      name: 'Dostawa',
      quantity: 1,
      price: bigToInt(shippingGrossAmount),
      vatRate: SHIPPING_VAT_RATE,
    });
  }

  // verify coupons
  const appliedCoupons = [];
  for (const cartCoupon of cartAppliedCoupons) {
    const coupon = getCouponById(cartCoupon.id);

    if (!coupon) {
      throw new Error(`Coupon ${cartCoupon.id} not found!`);
    }

    appliedCoupons.push(coupon);
  }

  const discountGrossAmount = calculateDiscountGrossAmount(
    appliedCoupons,
    subtotalGrossAmount
  );

  const totalGrossAmount = subtotalGrossAmount
    .minus(discountGrossAmount)
    .plus(shippingGrossAmount);

  return {
    currency: 'PLN',
    items,
    appliedCoupons,
    discountGrossAmount: bigToInt(discountGrossAmount),
    totalGrossAmount: bigToInt(totalGrossAmount),
  };
};
