export const FACEBOOK_URL = 'https://www.facebook.com/wojnawarzyw';
export const INSTAGRAM_URL = 'https://www.instagram.com/wojnawarzyw';
export const MESSENGER_URL = 'https://m.me/wojnawarzyw';

export const INFO_EMAIL = 'info@wojnawarzyw.pl';
export const CAREERS_EMAIL = 'careers@wojnawarzyw.pl';
export const PRESS_EMAIL = 'press@wojnawarzyw.pl';
export const HELP_EMAIL = 'pomoc@wojnawarzyw.pl';

// export const INFO_TEL = '+48 535 290 342';
// export const CAREERS_TEL = '+48 796 367 457';
// export const PRESS_TEL = '+48 530 074 300';

export const INFO_TEL = '+48 535 040 825'; // Max
// export const INFO_TEL = '+48 690 334 883'; // Weronika (temporary, Max on vacation ;) )
export const INFO_TEL2 = '+48 535 290 342'; // Eugene
export const INFO_TEL_18_WEEK = '+48 690 334 883'; // Weronika

// export const INFO_TEL = '+48 535 290 342'; // Eugene
export const CAREERS_TEL = '+48 796 367 457'; // Vadym
export const PRESS_TEL = '+48 535 290 342'; // Eugene

export const COMPANY_NAME = '7NINJAS SP. Z O.O.';
export const COMPANY_ADDR = 'Żołnierzy 9 Dywizji Piechoty 8, 35-083 Rzeszów';

export const COMPANY_NIP = '5170374943';
export const COMPANY_REGON = '364316451';
export const COMPANY_KRS = '0000615566';

export const RZE_OFFICE_ADDR = COMPANY_ADDR;
export const RZE_OFFICE_CITY = 'Rzeszów';

export const WAW_OFFICE_ADDR = 'Chmielna 73, 00-801 Warszawa';
export const WAW_OFFICE_CITY = 'Warszawa';
// export const WAW_OFFICE_DIRECTIONS_URL = 'https://goo.gl/maps/VBb3GoJBCrFFjzJb8';

export const HOTLINE_HOURS = 'Codziennie 9:00–18:00';
export const HOTLINE_HELP_HOURS = 'Codziennie 9:00–21:00';
export const OFFICE_HOURS = 'Pn–pt 9:00–18:00';

export const FREE_SHIPPING_AMOUNT = 16000;
export const HIGHT_SHIPPING_AMOUNT = 8000;
export const FREE_CHERRY_AMOUNT = 10000;
export const ONETIME_DELIVERY_AMOUNT_UNDER_LIMIT = 1999;

// min amount for order (without shipping) for small BOX
// e.g. MIN val 80zł, order 80zł + shipping is valid; order 75zł + shipping - not valid
export const SMALL_BOX_MIN_ORDER_AMOUNT = 8000;

export const VAT_RATES = [
  { label: '5%', value: 0.05 },
  { label: '8%', value: 0.08 },
  { label: '18%', value: 0.18 },
  { label: '23%', value: 0.23 },
];
