import { Link } from 'shared/ui/Link';
import clsx from 'clsx';
import { Logo } from './Logo';

export function LogoLink({
  className,
  children,
  imgClassName,
  isCustom,
  ...props
}) {
  return (
    <Link href="/" className={clsx('inline-flex', className)} {...props}>
      <Logo className={imgClassName} isCustom={isCustom} />
    </Link>
  );
}
