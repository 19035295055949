const PRODUCTS_VAT_RATE = 0.05;

const bigBoxBase = {
  type: 'box',
  active: true,
  name: 'Box Rodzinny 🏡',
  vatRate: PRODUCTS_VAT_RATE,
  currency: 'PLN',
  metadata: {
    invoiceName: 'Skrzynka warzyw i owoców (rodzinna)',
    featured: true,
    image: '/assets/products/DUZY.jpg',
    // desc1: '27 rodzajów produktów',
    // desc1: '27 rodzajów produktów (~15 kg)',
    // desc1: '27 rodzajów produktów (14-15 kg)',
    desc1: '20 rodzajów produktów (12 kg)',
    desc2: 'Dla 2 osób na 1 tydzień',
    storePrice: 200,
  },
};

const smallBoxBase = {
  type: 'box',
  active: true,
  name: 'Box Rekrut 🤓',
  vatRate: PRODUCTS_VAT_RATE,
  currency: 'PLN',
  metadata: {
    invoiceName: 'Skrzynka warzyw i owoców (rekrut)',
    image: '/assets/products/MALY.jpg',
    // desc1: '15 rodzajów produktów',
    // desc1: '15 rodzajów produktów (~8 kg)',
    // desc1: '15 rodzajów produktów (7-8 kg)',
    desc1: '13 rodzajów produktów (6 kg)',
    desc2: 'Dla 1 osoby na 1 tydzień',
    storePrice: 90,
  },
};

const products = [
  // A:
  // 70+40%=99.90 + 9.99 delivery
  // 35+40%=49.90 + 9.99 delivery
  {
    ...bigBoxBase,
    id: 'rodzinny-a',
    price: 9990,
    shippingGrossPrice: 999,
  },
  {
    ...smallBoxBase,
    id: 'rekrut-a',
    price: 4990,
    shippingGrossPrice: 999,
  },
  // B:
  // 70+55%=107.90 + 7.99 delivery
  // 35+60%=57.90 + 7.99 delivery
  {
    ...bigBoxBase,
    id: 'rodzinny-b',
    price: 10790,
    shippingGrossPrice: 799,
  },
  {
    ...smallBoxBase,
    id: 'rekrut-b',
    price: 5790,
    shippingGrossPrice: 799,
  },
  {
    ...bigBoxBase,
    id: 'rodzinny-c',
    price: 11990,
    shippingGrossPrice: 999,
  },
  {
    ...smallBoxBase,
    id: 'rekrut-c',
    price: 5990,
    shippingGrossPrice: 999,
  },
  // C:
  // 127
  // 67 + 6.90
  {
    ...bigBoxBase,
    id: 'rodzinny',
    price: 12700,
    shippingGrossPrice: 0,
  },
  {
    ...smallBoxBase,
    id: 'rekrut-shipping690',
    price: 6700,
    shippingGrossPrice: 690,
  },
  // LEGACY
  {
    ...bigBoxBase,
    id: 'rodzinny-batch4',
    price: 11700,
    shippingGrossPrice: 0,
  },
  {
    ...smallBoxBase,
    id: 'rekrut-shipping1290',
    price: 6700,
    shippingGrossPrice: 1290,
  },
  {
    ...smallBoxBase,
    id: 'rekrut-batch4',
    price: 5900,
    shippingGrossPrice: 0,
  },
  {
    ...smallBoxBase,
    id: 'rekrut-noshipping',
    price: 6700,
    shippingGrossPrice: 0,
  },
  {
    ...smallBoxBase,
    id: 'rekrut-5900-noshipping',
    price: 5900,
    shippingGrossPrice: 0,
  },
  {
    ...smallBoxBase,
    id: 'rekrut-6700',
    price: 6700,
    shippingGrossPrice: 0,
  },
];

const upsellProducts = [
  {
    date: '2021-05-05',
    id: 'szparagi-zielone',
    type: 'upsell',
    active: true,
    name: 'Szparagi Polskie (1 pęczek)',
    price: 1200,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Szparagi Polskie',
      amount: '1 pęczek',
      invoiceName: 'Dodatek (szparagi polskie)',
      image: '/assets/products/SZPA.png',
      desc: (
        <div>
          <p>
            Pyszne zielone szparagi będą doskonałym dodatkiem do sałatek,
            risotto, makaronu.
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'awokado',
    type: 'upsell',
    active: true,
    name: 'Awokado HASS (2 szt)',
    price: 1000,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Awokado HASS',
      amount: '2 szt',
      invoiceName: 'Dodatek (awokado)',
      image: '/assets/products/AWO.png',
      desc: (
        <div>
          <p>
            Lekko orzechowe, kremowe awokado jest idealnym składnikiem wielu
            kanapek (nasza ulubiona z łososiem) a także podstawą najlepszej
            salsy na świecie - Guacamole 🥑
          </p>
          <p>
            <b>Korzyści:</b> potas, miedź, magnez, żelazo, witaminy C, A, E. 🥑
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'awokado-fuerte',
    type: 'upsell',
    active: false,
    name: 'Awokado Fuerte (2 szt)',
    price: 790,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Awokado Fuerte',
      amount: '2 szt',
      invoiceName: 'Dodatek (awokado)',
      image: '/assets/products/AWOF.png',
      desc: (
        <div>
          <p>
            Lekko orzechowe, kremowe awokado jest idealnym składnikiem wielu
            kanapek (nasza ulubiona z łososiem) a także podstawą najlepszej
            salsy na świecie - Guacamole 🥑
          </p>
          <p>
            <b>Korzyści:</b> potas, miedź, magnez, żelazo, witaminy C, A, E. 🥑
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'guacamole',
    type: 'upsell',
    active: true,
    name: 'Zestaw Guacamole',
    price: 3400,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Zestaw Guacamole',
      amount: null,
      invoiceName: 'Dodatek (zestaw guacamole)',
      image: '/assets/products/GUAC.png',
      desc: (
        <div>
          <p>
            W tym zestawie kremowe awokado łączy się z chrupiącą cebulką,
            aromatycznymi pomidorkami i świeżą kolendrą. Dodaj limonkę oraz
            papryczkę i masz świetny dodatek do WSZYSTKIEGO 😀
          </p>
          <p>
            <b>Skład zestawu:</b> 4 avocado HASS, cebulka czerwona, pomidorki
            Cherry, kolendra, limonka, papryczki
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'pomidorki-cherry',
    type: 'upsell',
    active: true,
    name: 'Pomidorki Cherry (500g)',
    price: 1000,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Pomidorki Cherry',
      amount: '500g',
      invoiceName: 'Dodatek (pomidorki cherry)',
      image: '/assets/products/POMCH.png',
      desc: (
        <div>
          <p>
            Soczyste pomidorki Cherry, doskonały składnik sałatek, kanapek,
            sosów.
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'szparagi-biale',
    type: 'upsell',
    active: false,
    name: 'Szparagi białe (1 pęczek)',
    price: 1800,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Szparagi białe',
      amount: '1 pęczek',
      invoiceName: 'Dodatek (szparagi białe)',
      image: '/assets/products/SZPAB.png',
      desc: (
        <div>
          <p>
            Pyszne białe szparagi będą doskonałym dodatkiem do sałatek, risotto,
            makaronu.
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'brukselka',
    type: 'upsell',
    active: false,
    name: 'Brukselka (500g)',
    price: 700,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Brukselka',
      amount: '500g',
      invoiceName: 'Dodatek (brukselka)',
      image: '/assets/products/BRU.png',
      desc: (
        <div>
          <p>Postrach najmłodszych, koszmar wielu dorosłych.</p>
          <p>Ugotuj na parze, w piekarniku lub zaszalej i zjedz na surowo!</p>
          <p>
            <b>
              Źródło wapnia, żelaza, magnezu i fosforu, witaminów A, C, E, K.
            </b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'mieszanka-bakalii',
    type: 'upsell',
    active: true,
    name: 'Mieszanka bakaliowa Extra (200g)',
    price: 1000,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Bakalie',
      amount: '200g',
      invoiceName: 'Dodatek (mieszanka bakaliowa)',
      image: '/assets/products/BAK.png',
      desc: (
        <div>
          <p>
            Chrupiące migdały, orzechy włoskie, kremowe nerkowca i egzotyczne
            brazylijskie w połączeniu z rodzynkami i żurawinką suszoną na cukrze
            trzcinowym. Po prostu pycha!
          </p>
          <p>
            <b>Skład:</b> Żurawina, orzechy nerkowca, orzechy włoskie, migdał
            blanszowany, rodzynki sułtanskie.
          </p>
          <p>
            <b>Korzyści:</b> cenne tłuszcze nienasycone, błonnik, witaminy A, D,
            E, K.
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'seler-naciowy',
    type: 'upsell',
    active: true,
    name: 'Seler naciowy (1 szt)',
    price: 500,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Seler naciowy',
      amount: '1 szt',
      invoiceName: 'Dodatek (seler naciowy)',
      image: '/assets/products/SEL.png',
      desc: (
        <div>
          <p>Doskonały składnik wielu koktajli, sałatek, zup.</p>
          <p>
            <b>
              Źródło fosforu, magnezu, potasu oraz witamin A, B, C, D, E i K.
            </b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'flawsome-jablkowy',
    type: 'upsell',
    active: true,
    name: 'Flawsome jabłkowy (250ml)',
    price: 500,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Flawsome jabłkowy',
      amount: '250ml',
      invoiceName: 'Dodatek (flawsome jabłkowy)',
      image: '/assets/products/FLAPP.png',
      desc: (
        <div>
          <p>
            Flawsome! ratuje nadwyżki jabłek, wiśni i rabarbaru i używa je są do
            produkcji pysznych orzeźwiających napojów owocowych, które{' '}
            <b>nie posiadają konserwantów</b>, są <b>bez cukru</b> oraz{' '}
            <b>bez słodziku</b>. Opakowanie w 100% odnawialne.
          </p>
          <p>
            <b>Skład:</b> sok tłoczony z jabłek (60%), woda, aromaty naturalne,
            dwutlenek węgla, przeciwutleniacz: kwas askorbinowy.
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'flawsome-jablkowo-rabarbarowy',
    type: 'upsell',
    active: true,
    name: 'Flawsome jabłkowo-rabarbarowy (250ml)',
    price: 500,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Flawsome jabłkowo-rabarbarowy',
      amount: '250ml',
      invoiceName: 'Dodatek (flawsome jabłkowo-rabarbarowy)',
      image: '/assets/products/FLRHU.png',
      desc: (
        <div>
          <p>
            Flawsome! ratuje nadwyżki jabłek, wiśni i rabarbaru i używa je są do
            produkcji pysznych orzeźwiających napojów owocowych, które{' '}
            <b>nie posiadają konserwantów</b>, są <b>bez cukru</b> oraz{' '}
            <b>bez słodziku</b>. Opakowanie w 100% odnawialne.
          </p>
          <p>
            <b>Skład:</b> sok tłoczony z jabłek (51%), woda, sok tłoczony z
            rabarbaru (9%), aromaty naturalne, dwutlenek węgla,
            przeciwutleniacz: kwas askorbinowy.
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'flawsome-jablkowo-wisniowy',
    type: 'upsell',
    active: true,
    name: 'Flawsome jabłkowo-wiśniowy (250ml)',
    price: 500,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Flawsome jabłkowo-wiśniowy',
      amount: '250ml',
      invoiceName: 'Dodatek (flawsome jabłkowo-wiśniowy)',
      image: '/assets/products/FLCHE.png',
      desc: (
        <div>
          <p>
            Flawsome! ratuje nadwyżki jabłek, wiśni i rabarbaru i używa je są do
            produkcji pysznych orzeźwiających napojów owocowych, które{' '}
            <b>nie posiadają konserwantów</b>, są <b>bez cukru</b> oraz{' '}
            <b>bez słodziku</b>. Opakowanie w 100% odnawialne.
          </p>
          <p>
            <b>Skład:</b> sok tłoczony z jabłek (51%), woda, sok tłoczony z
            wiśni (9%), aromat naturalny, dwutlenek węgla, przeciwutleniacz:
            kwas askorbinowy.
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'cieciorka',
    type: 'upsell',
    active: true,
    name: 'Cieciorka (500g)',
    price: 500,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Cieciorka',
      amount: '500g',
      invoiceName: 'Dodatek (cieciorka)',
      image: '/assets/products/CIE.png',
      desc: (
        <div>
          <p>
            Podstawowy składnik wielu wegańskich dań i oczywiście Hummusu.
            Zawiera dawkę witamin i minerałów.
          </p>
          <p>
            <b>
              Źródłó fosforu, potasu, żelaza, błonniku oraz witamin B9 i B6.
            </b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'platki-zwykle',
    type: 'upsell',
    active: true,
    name: 'Płatki zwykłe (500g)',
    price: 500,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Płatki zwykłe',
      amount: '500g',
      invoiceName: 'Dodatek (płatki zwykłe)',
      image: '/assets/products/PLA.png',
      desc: (
        <div>
          <p>
            Płatki owsiane najmniej przetworzone. Uniwersalny składnik wielu
            śniadań, deserów, koktajli.
          </p>
          <p>
            <b>
              Źródło białka, aminokwasów, błonnika oraz witamin grupy B i E.
            </b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'quinoa-czarna',
    type: 'upsell',
    active: true,
    name: 'Quinoa czarna (500g)',
    price: 1300,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Quinoa czarna',
      amount: '500g',
      invoiceName: 'Dodatek (quinoa czarna)',
      image: '/assets/products/QUI.png',
      desc: (
        <div>
          <p>
            Odmiana quinoa bardziej intensywna w smaku. Zdrowszy zamiennik
            ziemniaka, ryżu lub makaronu.
          </p>
          <p>
            <b>Źródło nienasyconych kwasy tłuszczowych oraz witamin grupy E.</b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'tapioka',
    type: 'upsell',
    active: true,
    name: 'Tapioka (500g)',
    price: 800,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Tapioka',
      amount: '500g',
      invoiceName: 'Dodatek (tapioka)',
      image: '/assets/products/TAP.png',
      desc: (
        <div>
          <p>
            Składnik wielu pysznych deserów, świetnie łączy się z różnymi
            dodatkami.
          </p>
          <p>
            <b>
              Źródło fosfora, magnezu, potasu, żelaza oraz witamin C, B1, B2,
              B6.
            </b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'kasza-gryczana',
    type: 'upsell',
    active: true,
    name: 'Kasza gryczana niepalona (500g)',
    price: 700,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Kasza grycz. niepalona',
      amount: '500g',
      invoiceName: 'Dodatek (kasza gryczana)',
      image: '/assets/products/GRY.png',
      desc: (
        <div>
          <p>
            „Zdrowsza” odmiana zwykłej kaszy gryczanej, bogata w
            pełnowartościowe składniki odżywcze – witaminy i mikroelementy.
          </p>
          <p>
            <b>Źródło kwasu foliowego, wapnia, magnezu i witamin grupy B.</b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'soczewica-czerwona',
    type: 'upsell',
    active: true,
    name: 'Soczewica czerwona (500g)',
    price: 700,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Soczewica czerwona',
      amount: '500g',
      invoiceName: 'Dodatek (soczewica czerwona)',
      image: '/assets/products/SOCZ.png',
      desc: (
        <div>
          <p>
            Pyszna roślina strączkowa, często używana jako zamiennik mięsa ze
            względu na dużą zawartość białka roślinnego.
          </p>
          <p>
            <b>
              Źródło potasu, kwasu foliowego, wapnia, żelaza, witaminów A, C,
              B6, K.
            </b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'owoce-suszone',
    type: 'upsell',
    active: false,
    name: 'Mieszanka kompotowa (250g)',
    price: 1000,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Mieszanka kompotowa',
      amount: '250g',
      invoiceName: 'Dodatek (mieszanka kompotowa)',
      image: '/assets/products/SUSZ.png',
      desc: (
        <div>
          <p>
            Prawdziwa witaminowa BOMBA. Suszone polskie jabłka w idealnym
            połączeniu z wędzoną śliwką, gruszką i aronią.
          </p>
          <p>
            <b>Skład:</b> jabłka, gruszki, śliwka wędzona, aronia.
          </p>
          <p>
            <b>Korzyści:</b> potas, magnez, fosfor, żelazo, witaminy B, C, E.
          </p>
          <p>Może zawierać śladowe ilości glutenu, mleka, orzechów i soi.</p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'kuskus-izraelski',
    type: 'upsell',
    active: true,
    name: 'Kuskus izraelski (200g)',
    price: 500,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Kuskus izraelski',
      amount: '200g',
      invoiceName: 'Dodatek (kuskus izraelski)',
      image: '/assets/products/KUS.png',
      desc: (
        <div>
          <p>
            Dodatek, który pasuje niemal do wszystkiego. Świetnie smakuje
            zarówno na słodko jak i na słono.
          </p>
          <p>
            <b>Źródło potasu, miedzi, cynku oraz witamin grupy B.</b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'orzech-macadamia',
    type: 'upsell',
    active: true,
    name: 'Orzech macadamia (100g)',
    price: 1500,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Orzech macadamia',
      amount: '100g',
      invoiceName: 'Dodatek (orzech macadamia)',
      image: '/assets/products/MACADAM.png',
      desc: (
        <div>
          <p>
            Pyszny orzech o wyjątkowych wartościach odżywczych. Idealna
            przekąska na diecie Keto.
          </p>
          <p>
            <b>Źródło tłuszczy nienasyconych oraz witamin A, E, B.</b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'migdaly',
    type: 'upsell',
    active: true,
    name: 'Migdały (200g)',
    price: 1000,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Migdały',
      amount: '200g',
      invoiceName: 'Dodatek (migdały)',
      image: '/assets/products/MIGDAL.png',
      desc: (
        <div>
          <p>
            Pyszne orzechy idealne jako przekąska lub składnik deserów i ciast.
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'fasola-suszona',
    type: 'upsell',
    active: true,
    name: 'Fasola suszona (500g)',
    price: 1000,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Fasola suszona',
      amount: '500g',
      invoiceName: 'Dodatek (fasola suszona)',
      image: '/assets/products/FAS.png',
      desc: (
        <div>
          <p>
            Zdrowsza opcja fasoli z puszki. Ugotuj samodzielnie i użyj jako
            składnik sałatek, ciast oraz past.
          </p>
          <p>
            <b>Źródło białka, błonnika, żelaza oraz molibdenu.</b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'rodzynki',
    type: 'upsell',
    active: true,
    name: 'Rodzynki sułtanskie (200g)',
    price: 500,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Rodzynki sułtanskie',
      amount: '200g',
      invoiceName: 'Dodatek (rodzynki)',
      image: '/assets/products/RODZ.png',
      desc: (
        <div>
          <p>Pyszny składnik wypieków i ciast na święta Wielkanocne.</p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'mak',
    type: 'upsell',
    active: true,
    name: 'Mak niebieski (500g)',
    price: 1100,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Mak niebieski',
      amount: '500g',
      invoiceName: 'Dodatek (mak)',
      image: '/assets/products/MAKN.png',
      desc: (
        <div>
          <p>Idealny składnik wypieków i słodkości na święta wielkanocne</p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'jarmuż-polski',
    type: 'upsell',
    active: false,
    name: 'Jarmuż Polski (250g)',
    price: 800,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Jarmuż Polski',
      amount: '250g',
      invoiceName: 'Dodatek (jarmuż Polski)',
      image: '/assets/products/JAR.png',

      desc: (
        <div>
          <p>
            Pyszny składnik wielu sałatek i smoothie, swiętny w postaci chipsów.
          </p>
          <p>
            <b>Żródło magneza, potasu, foliany oraz witamin A, C, K, E.</b>
          </p>
        </div>
      ),
    },
  },
  {
    date: '2021-05-05',
    id: 'dynia-pizmowa',
    type: 'upsell',
    active: false,
    name: 'Dynia piżmowa',
    price: 700,
    shippingGrossPrice: 0,
    vatRate: PRODUCTS_VAT_RATE,
    currency: 'PLN',
    metadata: {
      name: 'Dynia piżmowa',
      amount: '~1.5kg',
      invoiceName: 'Dodatek (dynia piżmowa)',
      image: '/assets/products/DYN.png',
      desc: (
        <div>
          <p>
            Rozgrzewająca zupa z dyni? Pyszne ciasto? A może chrupiące frytki?
            Ty wybierasz!
          </p>
          <p>
            <b>Źródło fosforu, potasu, magnezu oraz witamin A, C, E, K</b>
          </p>
        </div>
      ),
    },
  },
];

export function getUpsellProducts() {
  return upsellProducts;
}

export function getAllProducts() {
  return [...products, ...upsellProducts];
}

export function getProductById(id: string) {
  return getAllProducts().find((x) => x.id === id);
}

// We need those for FAQ page
export const getSmallBox = () => getProductById('rekrut-shipping690');

export const getBigBox = () => getProductById('rodzinny');
